import BRAND_LOGO from 'src/assets/logo.svg';
import VENDOR_BRAND_LOGO from 'src/assets/logoVMC.svg';
import styled from 'styled-components';

const Wrapper = styled.div`
    text-align: center;
    margin-bottom: 32px;
`;

const Logo = styled.img`
    height: 36px;
`;

interface Props {
    vendor?: boolean;
}

export const VendorLogo = () => (
    <Wrapper>
        <Logo src={VENDOR_BRAND_LOGO} />
    </Wrapper>
);

export const ClientLogo = () => (
    <Wrapper>
        <Logo src={BRAND_LOGO} />
    </Wrapper>
);

const BrandLogo = ({ vendor }: Props) => {
    if (vendor) {
        return <VendorLogo />;
    }
    return <ClientLogo />;
};

export default BrandLogo;
