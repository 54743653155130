import { Box } from '@mui/material';
import React from 'react';
import { ClientLogoWithText } from 'src/lib/components/LogoWithText';
import styled from 'styled-components';
import BrandLogo, { VendorLogo } from './BrandLogo';

const Wrapper = styled.div<{ width: number }>`
    flex: auto;
    padding: 60px 0;
    ${({ width }) => `
        @media (max-width: ${width}px) {
            padding: 0;
            background: white;
        }
    `}
`;

const Paper = styled.div<{
    width: number;
}>`
    margin: 0 auto;
    width: 100%;
    background: white;
    padding: 32px;
    border: 1px solid #f7f7f7;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(218, 218, 218, 0.25);
    ${({ width }) => `
        max-width: ${width}px;
        @media (max-width: ${width}px) {
            border: none;
            border-radius: 0;
            box-shadow: none;
        }
    `}
`;

const Body = styled.div``;

interface Props {
    width: number;
    children: React.ReactNode;
    isVendor?: boolean;
    logoSubheading?: boolean;
}

const Container = ({ width, children, isVendor, logoSubheading }: Props) => {
    if (logoSubheading) {
        return (
            <Wrapper width={width}>
                {isVendor ? (
                    <Box mb={4}>
                        <VendorLogo />
                    </Box>
                ) : (
                    <ClientLogoWithText />
                )}
                <Paper width={width}>
                    <Body>{children}</Body>
                </Paper>
            </Wrapper>
        );
    }

    return (
        <Wrapper width={width}>
            <Paper width={width}>
                <BrandLogo vendor={isVendor} />
                <Body>{children}</Body>
            </Paper>
        </Wrapper>
    );
};

export default Container;
