import { Input, OutlinedInput, OutlinedInputProps } from '@mui/material';
import React from 'react';
import { Field, FieldProps } from './Field';
import withFormikConsumer from './withFormikConsumer';

type TextFieldVariant = 'outlined' | 'text';
export interface TextFieldProps
    extends Pick<
            FieldProps,
            'editable' | 'error' | 'helperText' | 'label' | 'tooltip' | 'wide' | 'required'
        >,
        Pick<
            OutlinedInputProps,
            | 'autoComplete'
            | 'autoFocus'
            | 'defaultValue'
            | 'disabled'
            | 'multiline'
            | 'name'
            | 'placeholder'
            | 'rows'
            | 'maxRows'
            | 'type'
            | 'onChange'
            | 'value'
            | 'size'
            | 'startAdornment'
            | 'endAdornment'
        > {
    trimWhiteSpace?: boolean;
    variant?: TextFieldVariant;
}

const Text: React.FC<TextFieldProps> = ({
    editable = true,
    error,
    helperText,
    label,
    tooltip,
    value,
    onChange,
    trimWhiteSpace = false,
    wide = false,
    required = false,
    variant = 'outlined',
    ...props
}) => {
    return (
        <Field
            editable={editable}
            error={error}
            label={label}
            tooltip={tooltip}
            helperText={helperText}
            wide={wide}
            required={required}
        >
            {!editable ? (
                value
            ) : variant === 'text' ? (
                <Input
                    error={!!error}
                    fullWidth
                    inputProps={{
                        onBlur: (event) => {
                            if (trimWhiteSpace && onChange) {
                                event.target.value = event.target.value.trim();
                                onChange(event);
                            }
                        },
                    }}
                    value={value}
                    onChange={onChange}
                    {...props}
                />
            ) : (
                <OutlinedInput
                    error={!!error}
                    fullWidth
                    inputProps={{
                        onBlur: (event) => {
                            if (trimWhiteSpace && onChange) {
                                event.target.value = event.target.value.trim();
                                onChange(event);
                            }
                        },
                    }}
                    value={value}
                    onChange={onChange}
                    {...props}
                />
            )}
        </Field>
    );
};
export const TextField = withFormikConsumer<OutlinedInputProps['value'], TextFieldProps>(Text);
