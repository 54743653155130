import styled from 'styled-components';

export const Label = styled.div<{
    editable: boolean;
    error: boolean;
    required?: boolean;
    inline?: boolean;
}>`
    ${({ error, editable, theme, required, inline = false }) => `
        margin-bottom: ${theme.spacing(editable ? 0.5 : 1)};
        text-align: left;
        color: ${
            error
                ? theme.palette.danger.main
                : editable
                ? theme.palette.text.primary
                : theme.palette.text.secondary
        };
        font-size: ${editable ? '16px' : '14px'};
        font-weight: 500;
        line-height: ${inline ? '' : '1.2'};
        ${required && editable ? "&:after {content: ' *'}" : ''}
    `}
`;
