import { Stack } from '@mui/material';
import { ReactNode } from 'react';
import { Color } from 'src/constants/palette';
import styled from 'styled-components';
import { Spinner } from './components/Spinner';

const ContentFrame = styled.div`
    position: relative;
`;

const LoadingFrame = styled.div`
    background-color: ${Color.White};
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    z-index: 999;
`;

type LoadingState = 'loading' | 'error' | 'loaded';

interface LoadingViewProps {
    state: LoadingState;
    errorView: ReactNode;
    children?: ReactNode;
}

export const LoadingView = ({ state, errorView, children }: LoadingViewProps) => {
    if (state === 'error') {
        return <>{errorView}</>;
    }
    if (state === 'loading') {
        return (
            <LoadingFrame>
                <Stack
                    width="100%"
                    height="100%"
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Spinner />
                </Stack>
            </LoadingFrame>
        );
    }
    return <ContentFrame>{children}</ContentFrame>;
};
