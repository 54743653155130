import * as Sentry from '@sentry/browser';
import { useNullableSessionUser } from '../../sessionContext';

const SentryUserContext = () => {
    const session = useNullableSessionUser();
    Sentry.configureScope((scope) => {
        if (session) {
            scope.setUser({
                id: session.sourceId.toString(),
                name: `${session.firstName} ${session.lastName}`,
            });
        } else {
            scope.clear();
        }
    });
    return null;
};

export default SentryUserContext;
