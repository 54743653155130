import { ApolloError } from '@apollo/client';
import { FormikProvider } from 'formik';
import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldErrors } from 'src/helpers/errorHandling';
import * as yup from 'yup';
import { FormContext } from './useForm';
interface Props
    extends React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
    apolloError?: ApolloError;
    value: FormContext<any>;
    children: React.ReactNode;
}
interface FieldData {
    required: boolean;
    hasDeps: boolean;
}

const FormMetadataContext = React.createContext<any>({});

export const useFormMetaData = () => {
    const context = React.useContext(FormMetadataContext);
    return context;
};
export default function FormProvider({ value, apolloError, children }: Props) {
    const { values, validationSchemaYup, submitCount, isValid, errors } = value;

    const { t } = useTranslation('validation');
    const { enqueueSnackbar } = useSnackbar();
    const previousSubmitCountRef = useRef(0);
    useEffect(() => {
        if (!isValid && submitCount > previousSubmitCountRef.current) {
            enqueueSnackbar(t('formValidationErrorMessage'), { variant: 'error' });
            // This has to be inside the conditional because isValid
            // updates after submitCount
            previousSubmitCountRef.current = submitCount;
        }
    }, [submitCount, isValid, enqueueSnackbar, t]);
    const [lastApolloError, setLastApolloError] = useState('');
    const [metaData, setMetaData] = useState<any>({});
    const apolloErrorStr = useMemo(() => JSON.stringify(apolloError), [apolloError]);
    useEffect(() => {
        //when loading flips true to false set the apollo error, if there is one
        if (lastApolloError === apolloErrorStr) {
            return;
        }
        const formattedErrors = {} as any;
        if (apolloError?.graphQLErrors) {
            const originalFieldErrors =
                (apolloError.graphQLErrors[0]?.extensions?.fields as FieldErrors | null) ?? {};
            mapKeys(originalFieldErrors, (error, apolloKey) => {
                const key = camelCase(apolloKey);
                formattedErrors[key] = {
                    value: values[key],
                    error,
                };
            });
            setMetaData({ ...metaData, apolloErrors: formattedErrors });
        }
        setLastApolloError(apolloErrorStr);
    }, [metaData, setMetaData, lastApolloError, values, apolloErrorStr, apolloError]);

    useEffect(() => {
        // Determine which fields are required according to the schema, which may include when() clauses
        // that require the forms current state to be evaluated.
        // Pre Yup 1.0.0 (in pre-release as of this writing) it seems the only way to check a schema against a
        // forms values without validating the form is with the reach() method.
        // When Yup 1.0.0 is released, we should update this to pass the values to the root schemas describe function.
        const objSchema = validationSchemaYup as yup.ObjectSchema<any>;
        if (objSchema === undefined) {
            return;
        }
        const lastSchemaCheck = metaData?.lastSchemaCheck;
        if (lastSchemaCheck !== undefined && !lastSchemaCheck.hasDeps) {
            return;
        }
        const valuesJson = JSON.stringify(values);
        if (lastSchemaCheck?.valuesJson === valuesJson) {
            return;
        }
        const requiredFields: { [key: string]: FieldData } = metaData?.requiredFields || {};
        let anyDeps = false;
        for (const fieldName in objSchema.fields) {
            if (
                !(fieldName in requiredFields) ||
                (fieldName in requiredFields && requiredFields[fieldName].hasDeps)
            ) {
                const fieldSchema = yup.reach(objSchema, fieldName, values);
                const hasDeps = !!(fieldSchema as any)?._deps?.length;
                anyDeps = anyDeps || hasDeps;
                requiredFields[fieldName] = {
                    required: !!fieldSchema
                        .describe()
                        .tests.find((test: any) => test.name == 'required'),
                    hasDeps,
                };
            }
        }
        setMetaData({
            ...metaData,
            requiredFields,
            lastSchemaCheck: { valuesJson, hasDeps: anyDeps },
        });
    }, [metaData, setMetaData, values, validationSchemaYup]);
    return (
        <FormikProvider value={value}>
            <FormMetadataContext.Provider value={metaData}>{children}</FormMetadataContext.Provider>
        </FormikProvider>
    );
}
