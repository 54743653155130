export const WORK_ORDER_REVIEWS_NOTIFICATION = 'WORK_ORDER_REVIEWS_NOTIFICATION';
export const CORE_MARKET = 'CORE_MARKET';
export const DIRECT_ASSIGNMENT = 'DIRECT_ASSIGNMENT';
export const DISABLE_ISSUE_TREE = 'DISABLE_ISSUE_TREE';
export const CONFIRM_CONTACT_INFO = 'CONFIRM_CONTACT_INFO';
export const KUSTOMER_HELP = 'KUSTOMER_HELP';
export const NOTIFICATION_TEST_LIST = 'NOTIFICATION_TEST_LIST';
export const VENDOR_CUSTOMERS = 'VENDOR_CUSTOMERS';
export const QUOTE_IMPROVEMENTS = 'QUOTE_IMPROVEMENTS';
export const UK_ADDRESSES = 'UK_ADDRESSES';
export const ADDRESS_VALIDATION = 'ADDRESS_VALIDATION';
export const RESQ_ON_DEMAND = 'RESQ_ON_DEMAND';
export const VENDOR_SOURCING_PARTS_ETA = 'VENDOR_SOURCING_PARTS_ETA';
export const VENDOR_ESCALATIONS = 'VENDOR_ESCALATIONS';
export const STAFF_SELECT_HIDE_ADMIN = 'STAFF_SELECT_HIDE_ADMIN';
export const KUSTOMER_WORKORDER_ESCALATIONS = 'KUSTOMER_WORKORDER_ESCALATIONS';
export const INTERNAL_PREVENTATIVE_MAINTENANCE = 'INTERNAL_PREVENTATIVE_MAINTENANCE';
export const VULCAN_VERIFIED_VENDOR = 'VULCAN_VERIFIED_VENDOR';
export const VULCAN_DISABLE_MARKETPLACE_PREMIUM = 'VULCAN_DISABLE_MARKETPLACE_PREMIUM';
export const MONEY_AUTO_AP_BATCHING = 'MONEY_AUTO_AP_BATCHING';
export const MONEY_RMC_AUTOPAY_SETTINGS = 'MONEY_RMC_AUTOPAY_SETTINGS';
export const HAL_SCHEDULING_CALLDOWN = 'HAL_SCHEDULING_CALLDOWN';
export const VULCAN_VENDOR_PLAN_LIMIT_CHANGES = 'VULCAN_VENDOR_PLAN_LIMIT_CHANGES';
export const INTERNAL_PREVENTATIVE_MAINTENANCE_SCHEDULING =
    'INTERNAL_PREVENTATIVE_MAINTENANCE_SCHEDULING';
export const MONEY_ORUM = 'MONEY_ORUM';
export const VORTEX_VENDOR_INVOICE_TABLE = 'VORTEX_VENDOR_INVOICE_TABLE';
export const VORTEX_OTHER_CUSTOMERS = 'VORTEX_OTHER_CUSTOMERS';
export const AUTOBOTS_SENSOR_DATA_EXPORTS = 'AUTOBOTS_SENSOR_DATA_EXPORTS';
export const VORTEX_SUBMIT_INVOICE = 'VORTEX_SUBMIT_INVOICE';
export const VULCAN_ROLES_AND_PERMISSIONS = 'VULCAN_ROLES_AND_PERMISSIONS';
export const VULCAN_MULTIPLE_VENDOR_TOS = 'VULCAN_MULTIPLE_VENDOR_TOS';
export const VORTEX_OFF_RESQ_CUSTOMER_PORTAL = 'VORTEX_OFF_RESQ_CUSTOMER_PORTAL';
export const AUTOBOTS_CALENDAR_VIEW = 'AUTOBOTS_CALENDAR_VIEW';
export const AUTOBOTS_STAFF_SCHEDULE = 'AUTOBOTS_STAFF_SCHEDULE';
export const VULCAN_DISPATCH_TO_INVITED_VENDOR = 'VULCAN_DISPATCH_TO_INVITED_VENDOR';
export const VULCAN_VENDOR_MAINTENANCE_PLANS = 'VULCAN_VENDOR_MAINTENANCE_PLANS';
