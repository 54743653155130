import { Theme } from '@mui/material/styles/createTheme';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import palette, { Color } from 'src/constants/palette';
import { parseFileUrl } from 'src/helpers/fileUrl';
import styled, { useTheme } from 'styled-components';
import { useTrack } from '../analytics';
import { AnalyticsEvent, AnalyticsEventType, ClickElementType } from '../analytics/events';

const StyledExternalLink = styled.a`
    color: ${palette.link};
    text-decoration: underline;
    font-weight: 400;
    :hover {
        color: ${palette.link};
        filter: brightness(65%);
        // Override antd, which unsets underline
        text-decoration: underline;
    }
`;

const StyledRouterLink = styled(RouterLink)`
    color: ${palette.link};
    text-decoration: underline;
    font-weight: 400;
    :hover {
        color: ${palette.link};
        filter: brightness(65%);
        // Override antd, which unsets underline
        text-decoration: underline;
    }
`;

export interface LinkProps
    extends Pick<React.HTMLProps<HTMLAnchorElement>, 'target' | 'rel' | 'style' | 'download'> {
    to?: string | null;
    trackingId?: string;
}

/** Please set trackingId explicitly if link is for a dynamic label
 * (eg. phone numbers, emails, work order codes, etc.)
 */
export const Link: React.FC<LinkProps> = ({ to, trackingId, children, ...props }) => {
    const track = useTrack();
    if (!to) {
        return (
            <StyledExternalLink
                {...props}
                onClick={() => {
                    track(AnalyticsEvent.ElementClicked, {
                        trackingId,
                        label: children?.toString() || null,
                        link: to || null,
                        elementType: ClickElementType.LINK,
                        eventType: AnalyticsEventType.ANALYTICS,
                        option: null,
                    });
                }}
            >
                {children}
            </StyledExternalLink>
        );
    }

    const fileProps = parseFileUrl(to);
    if (to.startsWith('/')) {
        return (
            <StyledRouterLink
                {...props}
                to={to}
                onClick={() => {
                    track(AnalyticsEvent.ElementClicked, {
                        trackingId,
                        label: children?.toString() || null,
                        link: to,
                        elementType: ClickElementType.LINK,
                        eventType: AnalyticsEventType.ANALYTICS,
                        option: null,
                    });
                    if (fileProps) {
                        track(AnalyticsEvent.FileDownloaded, {
                            trackingId,
                            label: children?.toString() || null,
                            option: null,
                            link: null,
                            elementType: null,
                            fileLocation: fileProps.fileLocation,
                            fileName: fileProps.fileName,
                            fileType: fileProps.fileType,
                            eventType: AnalyticsEventType.ANALYTICS,
                        });
                    }
                }}
            >
                {children}
            </StyledRouterLink>
        );
    }
    return (
        <StyledExternalLink
            {...props}
            href={to}
            onClick={() => {
                track(AnalyticsEvent.ElementClicked, {
                    trackingId,
                    label: children?.toString() || null,
                    link: to,
                    elementType: ClickElementType.LINK,
                    eventType: AnalyticsEventType.ANALYTICS,
                    option: null,
                });
                if (fileProps) {
                    track(AnalyticsEvent.FileDownloaded, {
                        trackingId,
                        label: children?.toString() || null,
                        fileLocation: fileProps.fileLocation,
                        fileName: fileProps.fileName,
                        fileType: fileProps.fileType,
                        eventType: AnalyticsEventType.ANALYTICS,
                        option: null,
                        link: null,
                        elementType: null,
                    });
                }
            }}
        >
            {children}
        </StyledExternalLink>
    );
};

export interface TextWithEmbeddedLinkProps {
    leadingText: string;
    linkText: string;
    followingText: string;
    link: string;
    linkColor?: keyof Theme['resqPalette']['text'];
}

export const TextWithEmbeddedLink: React.FC<TextWithEmbeddedLinkProps> = ({
    leadingText,
    linkText,
    followingText,
    link,
    linkColor,
}) => {
    const theme = useTheme();
    const isExternalLink = link.includes('://');
    return (
        <div>
            {leadingText}
            {isExternalLink ? (
                <Link
                    style={{
                        color: linkColor ? (theme.resqPalette.text[linkColor] as Color) : undefined,
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                    to={link}
                >
                    {linkText}
                </Link>
            ) : (
                <Link
                    style={{
                        color: linkColor ? (theme.resqPalette.text[linkColor] as Color) : undefined,
                    }}
                    to={link}
                >
                    {linkText}
                </Link>
            )}
            {followingText}
        </div>
    );
};

export interface EmbeddedLinkProps {
    leadingText?: string;
    followingText?: string;
    link?: string;
    onClick?: () => void;
}

export const EmbeddedLink: React.FC<EmbeddedLinkProps> = ({
    leadingText,
    children,
    followingText,
    link,
    onClick,
    ...props
}) => {
    return (
        <>
            {leadingText}
            {leadingText ? ' ' : ''}
            <StyledExternalLink href={link} onClick={onClick} {...props}>
                {children}
            </StyledExternalLink>
            {followingText ? ' ' : ''}
            {followingText}
        </>
    );
};
