import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EnvelopeIcon, InformationCircleIcon, PhoneIcon } from 'src/icons';
import { Link } from 'src/lib/components/Link';
import styled, { useTheme } from 'styled-components';

const DummyBox = styled(Box).attrs({ component: 'footer' })(({ theme }) => ({
    opacity: 0,
    pointerEvents: 'none',
}));

const StyledBox = styled(Box).attrs({ component: 'footer' })(({ theme }) => ({
    position: 'fixed',
    bottom: 0,
    right: 0,
    left: 0,
}));

const StyledIconBox = styled(Box)(({ theme }) => ({
    fontSize: '16px',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(1),
}));

const FooterContents: React.FC = () => {
    const { t } = useTranslation('auth.login');
    const theme = useTheme();
    return (
        <Grid
            container
            padding={2}
            gap={{ xs: 1, md: 2 }}
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="center"
            alignItems="center"
            bgcolor={theme.resqPalette.surface.hovered}
            borderTop={`1px solid ${theme.resqPalette.border.subdued}`}
        >
            <Grid item>
                <Typography variant="h4">{t('needHelpLoggingIn')}</Typography>
            </Grid>
            <Grid item>
                <StyledIconBox>
                    <PhoneIcon height="20px" />
                    <Link to={t('hrefResqNumber')} trackingId="login_help_banner_phone">
                        {t('resqNumber')}
                    </Link>
                </StyledIconBox>
            </Grid>
            <Grid item>
                <StyledIconBox>
                    <EnvelopeIcon height="20px" />
                    <Link to={t('hrefResqEmail')} trackingId="login_help_banner_email">
                        {t('resqEmail')}
                    </Link>
                </StyledIconBox>
            </Grid>
            <Grid item>
                <StyledIconBox>
                    <InformationCircleIcon height="20px" />
                    <Link to={t('hrefResqTOS')} trackingId="login_help_banner_tos">
                        {t('resqTOS')}
                    </Link>
                </StyledIconBox>
            </Grid>
        </Grid>
    );
};

export const ContactFooter: React.FC = () => {
    const { t } = useTranslation('auth.login');
    return (
        <>
            {/* Dummy Footer to leave space at the bottom of the page */}
            <DummyBox>
                <FooterContents />
            </DummyBox>
            <StyledBox>
                <FooterContents />
            </StyledBox>
        </>
    );
};
