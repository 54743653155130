import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/browser';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import ReactDOM from 'react-dom';
import './antd.css';
import configureI18Next from './configureI18Next';
import {
    ENVIRONMENT,
    IS_LIVE_ENVIRONMENT,
    RELEASE_VERSION,
    SENTRY_DSN,
} from './constants/environment';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { App } from './routes/App';

datadogRum.init({
    applicationId: '15baf5ce-527c-4fca-a5bf-317cb8836801',
    clientToken: 'pub377b7334467bf34f1fd9bb0d1a2072a4',
    site: 'datadoghq.com',
    service: 'web-app',
    env: ENVIRONMENT,
    version: RELEASE_VERSION,
    useSecureSessionCookie: true,
    trackSessionAcrossSubdomains: true,
    sampleRate: ENVIRONMENT == 'staging' ? 100 : 0, // test in staging first
    premiumSampleRate: 0, // Disable Session Replay
    trackInteractions: ENVIRONMENT == 'staging', // test in staging first
});

configureI18Next();

try {
    Sentry.init({
        release: RELEASE_VERSION,
        dsn: SENTRY_DSN,
        environment: ENVIRONMENT,
        debug: !IS_LIVE_ENVIRONMENT,
        enabled: IS_LIVE_ENVIRONMENT,
        integrations: [
            new CaptureConsoleIntegration({
                levels: ['error'],
            }),
        ],
    });
} catch (e) {
    console.error('Sentry was not set up correctly', e);
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
